import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PageTemplate = ({ pageContext: { page } }) => (
  <Layout>
    <Seo title={page.name} description={page.title} />
    <div className="page-standard">
      <h1>{page.title1}</h1>
      <h2>{page.title2}</h2>
      <h3>{page.title3}</h3>
      <h4>{page.title4}</h4>
      <p>{page.content}</p>
    </div>
  </Layout>
)


export default PageTemplate
